@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

:root {
  --primary-color: #197cde;
  --secondary-color: #197cde;
  --hover-color: #0e4985;
  --black: #333333;
  --white: #FFFFFF;
  --gray: #979797;
  --gray-dark: #444A53;

  --font-size-24: 24px;
  --font-size-18: 18px;
  --font-size-16: 16px;
  --font-size-14: 14px;
  --font-size-12: 12px;

  --border-radius: 10px;

  --container-width: 400px;
}

body, button {
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Poppins', sans-serif;
  color: var(--black);
  font-size: var(--font-size-16);
  font-weight: 500;
  box-sizing: content-box;
}

a, 
a:visited {
  color: var(--secondary-color);
  text-decoration: none;
  transition: 0.5s;
}
.btn:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}
a:hover {
  color: var(--hover-color);
  text-decoration: none;
}

input {
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
}
input:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-size: var(--font-size-24);
  font-weight: 500;
  color: var(--secondary-color);
}

header {
  text-align: center;
}
.app-page header {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  background-color: var(--primary-color);
  padding: 20px 0;
  min-height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.app-page header h1 {
  color: #fff;
}

.public-header-page > div {
  padding: 20px;
}
.public-header-page header img {
  max-width: 300px;
}
.form-page > div {
  background-color: var(--white);
  border-radius: var(--border-radius);
}
.page > div {
  width: var(--container-width);
  max-width: 100vw;
}
.page {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
  background-color: #E5E5E5;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.page-body {
  padding: 20px 20px 117px 20px;
  background: var(--white);
  flex-grow: 1;
  position: relative;
}
.app-page > div {
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Forms */
.row-container {
  display: flex;
  gap: 20px;
}
.field-container.field-disabled input {
  background-color: #ddd;
  border-color: #ddd;
}
.field-container {
  margin: 30px 0 10px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
}
.field-container label[for="pressure-high"] {
  min-width: 200px;
}
.field-container label {
  min-height: 27px;
  color: var(--gray);
  font-size: var(--font-size-18);
  margin-bottom: 10px;
}
.field-container .input {
  min-height: 60px;
  display: flex;
  align-items: center;
}
.field-container .input,
.field-container input {
  border: 1px solid var(--gray);
  padding: 15px 30px;
  line-height: 35px;
  border-radius: var(--border-radius);
  font-size: var(--font-size-14);
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
}
.field-container .dropdown ul .menu-item {
  padding: 10px 0;
}
.field-container .dropdown ul {
  box-sizing: border-box;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  top: 60px;
  border-radius: 0;
}
.field-container .dropdown > button::after {
  right: 30px;
  top: 30px;
}
.field-container .dropdown > button span {
  color: var(--black);
}
.field-container .dropdown > button {
  width: 100%;
  padding: 15px 30px;
  line-height: 35px;
  text-align: left;
}
.form .link {
  font-size: var(--font-size-12);
  text-align: right;
  font-weight: 300;
}
.form .after-submit {
  font-weight: 300;
  font-size: var(--font-size-12);
  text-align: center;
}
.field-container #date {
  padding: 15px 0;
}
.add-item input {
  text-align: center;
}
.react-datepicker__day--selected {
  background-color: var(--secondary-color);
}
.btn.disabled {
  cursor: auto;
}
.btn.btn-gray:hover {
  background-color: var(--black);
}
.btn.btn-gray {
  background-color: var(--gray);
}
.btn {
  background-color: var(--primary-color);
  font-size: var(--font-size-18);
  border-radius: 56px;
  color: var(--white);
  width: min(calc(100vw - 40px), 300px);
  text-align: center;
  border: 0 none;
  line-height: 65px;
  height: 65px;
  margin: 20px auto;
  transition: 0.5s;
  display: block;
}
.btn-loader.white-bg {
  color: var(--gray);
}
.btn-loader {
  width: 15px;
  height: 15px;
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
  color: var(--white);
  animation: fill 1s ease-in infinite alternate;
}
.btn-loader::before , .btn-loader::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 28px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}
.btn-loader::after {
  left: auto;
  right: 28px;
  animation-duration: 1.1s;
}
@keyframes fill {
 0% {  box-shadow: 0 0 0 1px inset }
 100%{ box-shadow: 0 0 0 5px inset }
}

/* Notifications */
#notifications .notification.error {
  background-color: #e74c34;
}
#notifications .notification {
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 3px;
  padding: 5px 15px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: var(--font-size-12);
}

/* Navigation */
nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
nav > div {
  display: flex;
  text-align: center;
  justify-content: space-around;
  width: var(--container-width);
  padding: 20px 0;
  background-color: var(--white);
  margin: 0 auto;
  border-top: 1px solid #d6d4d4;
}
nav > div > div.active:hover,
nav > div > div:hover {
  color: var(--hover-color);
}
nav > div > div.active {
  color: var(--secondary-color);
}
nav > div > div {
  width: 33%;
  position: relative;
  cursor: pointer;
}
nav svg {
  height: 24px;
}
nav > div > div:hover {
  --black: var(--hover-color);
  --primary-color: var(--hover-color);
}
.add-item-btn:hover,
nav .active {
  --black: var(--primary-color);
}
.unread-bubble {
  position: absolute;
  background-color: red;
  width: 15px;
  height: 15px;
  font-size: 10px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
  line-height: 15px;
  border-radius: 10px;
  align-self: center;
  top: -10px;
  z-index: 2;
  right: 40%;
}

/* Loader Page */
.loader-page {
  width: 100vw;
  height: 100vh;
}

/* Logout */
.app-page > div {
  position: relative;
}
.logout {
  position: absolute;
  display: flex;
  top: 10px;
  right: 20px;
  font-size: var(--font-size-14);
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.logout svg {
  height: 20px;
}

/* Chat Page */
.chat-with {
  color: var(--gray-dark);
  font-size: var(--font-size-18);
  text-align: center;
  margin: 20px 0 30px;
}
.message.own > div {
  background-color: var(--black);
  color: var(--white);
  
}
.message.own {
  justify-content: flex-end;
}
.message > div {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  font-size: var(--font-size-14);
  padding: 15px;
  margin-bottom: 30px;
  width: 70%;
}
.message {
  justify-content: flex-start;
  display: flex;
  font-weight: 400;
}
.message.own .time {
  text-align: right;
}
.message .time {
  color: var(--primary-color);
  font-size: var(--font-size-12);
}
.messages {
  margin-top: 20px;
}
.no-messages {
  color: var(--gray);
  text-align: center;
}
.new-message input {
  padding: 17px;
  width: calc(100% - 80px);
  border: 0 none;
  background: transparent;
  font-weight: 500;
  font-size: var(--font-size-14);
}
.new-message > div > div {
  display: flex;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  margin: 0 10px;
  align-items: center;
}
.new-message > div {
  width: var(--container-width);
  background-color: var(--white);
  margin: 0 auto;
}
.new-message {
  justify-content: space-between;
  display: flex;
  margin-right: 12px;
  position: fixed;
  bottom: 96px;
  z-index: 2;
  left: 0;
  width: 100%;
}
.new-message svg:hover {
  --primary-color: var(--hover-color);
}
.new-message svg {
  margin-right: 20px;
  cursor: pointer;
}
.new-message.sending {
  opacity: 0.6;
}
.chat-page .page-body {
  padding-bottom: 165px;
}
.last-message {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.conversation + .conversation {
  border-top: 1px solid var(--gray);
}
.conversation {
  cursor: pointer;
  height: 61px;
  padding: 10px 0;
}
.conversation-info .unread-bubble {
  left: -25px;
  top: 5px;
  right: auto;
}
.conversation-info {
  width: calc(var(--container-width) - 50px - 44px);
  font-weight: 400;
  position: relative;
}
.conversations .user-avatar {
  width: 44px;
}
.conversations .last-message {
  color: var(--gray);
  font-size: var(--font-size-12);
}
.conversations .posted-at {
  color: var(--primary-color);
  font-size: var(--font-size-12);
}
.conversations .with-users {
  font-size: var(--font-size-16);
}
.conversations .conversation {
  display: flex;
  align-items: center;
  gap: 10px;
}
.back-arrow {
  position: absolute;
  top: -50px;
  left: 10px;
  cursor: pointer;
  padding: 10px;
}

/* Records Page */
.record-row + .record-row {
  border-top: 1px solid var(--gray);
}
.record-row {
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  font-size: var(--font-size-12);
}
.record-row > div {
  flex: 1;
}
.record-row-header {
  margin-top: 20px;
  color: var(--primary-color);
}
.open .add-item-btn {
  font-size: var(--font-size-18);
}
.open .add-item-btn svg {
  height: 55px;
}
.open .add-items-form {
  display: block;
}
.add-items-form {
  margin: 20px;
  display: none;
}
.add-item-btn svg {
  height: 34px;
}
.add-item.open {
  width: 95%;
}
.add-item {
  width: 60%;
  margin: 0 auto;
  border: 1px solid var(--primary-color);
  margin-top: -56px;
  margin-bottom: 20px;
  background-color: var(--white);
  border-radius: var(--border-radius);
}
.add-item-btn:hover {
  color: var(--secondary-color);
}
.add-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 72px;
  font-size: var(--font-size-12);
  cursor: pointer;
}
.heart-rate-field {
  width: calc(50% - 10px);
}
.add-item .submit-buttons {
  display: flex;
  gap: 20px;
}

/* Users */
.user-row + .user-row {
  border-top: 1px solid var(--gray);
}
.user-row {
  line-height: 60px;
  display: flex;
  height: 61px;
  padding: 10px 0;
}
.user-row svg {
  align-self: center;
  width: 40px;
}
.pagination {
  list-style: none;
  display: flex;
  gap: 15px;
  justify-content: center;
}
.pagination li:hover a,
.pagination li.active a {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white);
}
.pagination li a {
  font-size: var(--font-size-14);
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  padding: 6px 14px;
  color: var(--black);
}
.users-top-filters {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  justify-content: space-between;
}
.users-top-filters input {
  padding: 17px;
  width: calc(100% - 80px);
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  background: transparent;
  font-weight: 500;
  font-size: var(--font-size-14);
}
.user-row .user-name {
  flex: 1;
  padding-left: 10px;
}
.user-row svg.edit-user {
  height: 25px;
  margin-left: auto;
  width: 25px;
  cursor: pointer;
  padding: 30px 0 30px 20px;
}
.user-row svg.view-records {
  cursor: pointer;
  height: 25px;
  margin-left: auto;
  width: 25px;
  padding: 30px 20px 30px 20px;
}
.dropdown .menu-item button:hover,
.dropdown .selected {
  color: var(--primary-color);
}
.dropdown > button {
  color: var(--gray-dark);
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  padding: 0 20px;
  font-size: var(--font-size-14);
  cursor: pointer;
}
.dropdown.open > button::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.dropdown > button::after {
  display: inline-block;
  right: 10px;
  content: "";
  top: 25px;
  position: absolute;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown .menu-item button {
  font-size: var(--font-size-14);
  border: 0 none;
  cursor: pointer;
  width: 100%;
  padding: 0;
  text-align: left;
}
.dropdown ul {
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);
  position: absolute;
  top: 70px;
  list-style: none;
  margin: 0;
  padding: 15px;
  background-color: var(--white);
}
.dropdown {
  display: flex;
  justify-content: end;
  position: relative;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  :root {
    --container-width: 700px;
  }
  .app-page header {
    min-height: 100px;
    flex-direction: row;
    gap: 20px;
  }
}